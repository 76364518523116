import React from "react";
import useTranslation from "hooks/useTranslation";

import PageLayout from "layouts/Page";

import * as Icons from "components/Icons";
import LocalizedLink from "components/LocalizedLink";
import SEO from "components/SEO";

export default () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <SEO title={t`not_found:title`} />
      <div className="max-w-lg mx-auto px-4 space-y-4 text-center">
        <Icons.Pizza size={8} color="secondary" />
        <h1 className="index__title">{t`not_found:title`}</h1>
        <LocalizedLink
          to="/"
          className="btn btn--primary btn--md font-bold"
        >{t`not_found:action`}</LocalizedLink>
      </div>
    </PageLayout>
  );
};
